.singlePage {
  padding: 50px 0;
  background: #fff;
  margin-left:30px;
  margin-right: 30px;
}
.singlePage .right {
  margin-top: 30px;
}
.singlePage img {
  width: 50%;

  border-radius: 10px;
 margin: auto;
 display: block;
  
 

 
 
}
.singlePage h1 {
  font-size: 30px;
  font-weight: 500;
}
.singlePage p {
  margin: 20px 0;
  font-size: 18px;
  line-height: 2;
  text-transform: capitalize;
}
.singlePage .buttons {
  display: flex;
  justify-content: flex-end;
}
.singlePage .button {
  margin-left: 20px;
  border: none;
}
.singlePage .button:nth-child(1) {
  background: #10ac84;
}
.singlePage .button:nth-child(2) {
  background: #ee5253;
}
.singlePage .header-img {
  width: 70%;

  border-radius: 10px;
 margin: auto;
 display: block;
  

}
@media (max-width:768px) {
  .singlePage p {
    margin: 20px 0;
    font-size: 11px;
    line-height: 2;
    text-transform: capitalize;
  }
  .singlePage h1 {
    font-size: 16px;
    font-weight: 500;
  }
  .singlePage {
    margin-left:0;
    margin-right: 0;
  }
 
  
}

