.blog {
  margin: 50px 0;
}
.blog .boxItems {
  transition: 0.5s;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 20px;
  background: #fff;
  
}
.blog .boxItems:hover {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  cursor: pointer;
  transform: translateY(-5px);
}
.blog img {
  width: 100%;
  height: 250px;
  object-fit:cover;
  border-radius: 10px;
  margin-bottom: 20px;

}
.blog .img {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
}
.blog .icon {
  margin-right: 10px;
  font-size: 20px;
}
.blog .tag a {
  color: blue;
  opacity: 0.7;
}
.blog .tag {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.blog h3 {
  font-weight: 500;
  font-size: 18px;
}
.blog p {
  color: #999;
  font-weight: 400;
  margin: 20px 0;
  font-size: 16px;
  line-height: 25px;
}
.blog .date {
  display: flex;
  align-items: center;
}
.blog .date label {
  display: block;
  margin-right: 20px;
}
@media screen and (max-width:768px){
 

  .blog p {
    color: #999;
    font-weight: 400;
    margin: 20px 0;
    font-size: 11px;
    line-height: 25px;
  }
  .blog h3 {
    font-size: 12px;
  }
  .blog img {
   
    width: 100%;
  height: 160px;
  object-fit:fill;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 5px;
 


  }
  .blog label {
    font-size: 12px;
  }
  .blog  .boxItems {
    transition: 0.5s;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 10px;
    background: #fff;
    width: 100%;
    
   
   
  }

}
@media screen and (min-width:769px ) and (max-width:1281px){

  .blog img {
    width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;

  }
  .blog h3 {
    font-weight: 500;
    font-size: 15px;
  }
  .blog p {
    color: #999;
    font-weight: 400;
    margin: 20px 0;
    font-size: 13px;
    line-height: 25px;
  }
}
