.commentBox {
    margin: 30px 0;
    margin-left: 30px;
    margin-right: 30px;
    border-color: black;
    font-size: small;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .commentBox .container {
    max-width: 100%;
    background-color:gainsboro;
  }
  .commentBox .inpt {
    border-radius: 10px;
    border-color: black;
    
  }
  .commentBox textarea {
    border-radius: 10px;
    border-color: black;
    

  }
  .commentBox .comment-div {
    display: flex;
    justify-content: flex-end;

  }
  .commentBox .comment-btn {
    background-color: #212121;
    border: 1px solid;
    border-color: #212121;
    border-radius: 6px;
    height: 30px;
    line-height: 2px;

    
    /* background-color: black;
    font-size:small;
    padding: 0;
    box-sizing:border-box;
    margin: 0;
    color: azure;
    text-align: center; */
 
  }
  .commentBox i {
    font-size: 25px;
    margin-right: 20px;
    margin-top: -20px;
  }
  .commentBox img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .commentBox .img {
    position: relative;
  }
  .commentBox form {
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: none;
  }
  .commentBox .inputfile input {
    width: 100%;
    height: 300px;
    position: relative;
    z-index: 2;
    opacity: 0;
  }
  .commentBox .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 1;
    border-radius: 5px;
  }
  .highligh {
    background-color:bisque;
    padding: 7px 7px 7px 10px;
    border: 1px solid #ddd;
    -moz-box-shadow: 3px 3px rgba(0,0,0,0.1);
    -webkit-box-shadow: 3px 3px rgba(0,0,0,0.1);
    box-shadow: 3px 3px rgba(0,0,0,0.1);
    margin: 20px 0 20px 0;
    overflow: scroll;
  
  }
 
  