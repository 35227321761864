header {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: relative;
  z-index: 44;
}
/* nav */
nav ul {
  display: flex;
}
nav a {
  text-transform: capitalize;
  margin-right: 30px;
  color: #000;
}
/* User */
.profile {
  margin-left: 10px;
  cursor: pointer;
}
.profile .image {
  display: flex;
  padding-bottom: 20px;
}
.profile .image img {
  margin-right: 20px;
}
.profile button {
  font-size: 17px;
  font-weight: 500;
}
.profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.openProfile {
  color: #000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: absolute;
  top: 80px;
  right: 10px;
  width: 250px;
  background: #fff;
}
.openProfile .box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  transition: 0.5s;
}
.openProfile .icon {
  font-size: 20px;
  margin-right: 20px;
}
.openProfile h4 {
  font-size: 15px;
  font-weight: 500;
}
.openProfile button:hover {
  background: rgba(147, 75, 255, 0.075);
}
header.active {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 4px 0 rgb(115 115 115 / 20%);
  z-index: 9999;
  background: #fff;
}
.svg-text {
  font-size: 120px;
  font-weight: bold;

}
.svg-base {
  width: 250px;
  height: 40px;
}
@media screen and (max-width: 768px) {
  header {
    background: #fff;
    padding: 0px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    position: relative;
    z-index: 44;
  }
  header .search {
    display: none;
  }
  nav {
    display: none;
  }
  .profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
  .openProfile h4 {
    font-size: 12px;
    font-weight: 500;
  }
  .profile {
   
    cursor: pointer;
  }
   .svg-text {
    font-size: 80px;
    font-weight: normal;
  
  }
  .svg-base {
    width: 180px;
    height: 40px;
  }
  
}
